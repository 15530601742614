import { useEffect, useState } from 'react';

import BpkSelectableChip from '@skyscanner/backpack-web/bpk-component-chip';
import BpkMobileScrollContainer from '@skyscanner/backpack-web/bpk-component-mobile-scroll-container';
import BpkSectionHeader from '@skyscanner/backpack-web/bpk-component-section-header';

import {
  ACTION_TYPE,
  COMPONENT_ACTION,
  COMPONENT_NAME,
  LOAD_STATUS,
} from '../../constants';
import logMiniEventHelper from '../../mini-event/logMiniEventHelper';
import CardRail from '../CardRail';
import InventoryCard from '../InventoryCard';

import type { InventoryCardProps } from '@skyscanner-internal/falcon-shared-types/types/InventoryCardProps';
import type { StaycationProps } from '@skyscanner-internal/falcon-shared-types/types/hotels-components/Staycation';

import STYLES from './Staycation.module.scss';

const Staycation = ({ description, sections, title }: StaycationProps) => {
  const [activeGroupIndex, setActiveGroupIndex] = useState(0);
  const [mobileScrollerRef, setMobileScrollerRef] =
    useState<HTMLElement | null>(null);
  const hasSections = !!sections?.length;

  useEffect(() => {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_LOADED,
      component_name: COMPONENT_NAME.STAYCATION,
      load_status: hasSections ? LOAD_STATUS.LOADED : LOAD_STATUS.INVALID,
    });
  }, [hasSections]);

  if (!hasSections) {
    return null;
  }

  const handleOnClickCityPill = (index: number, label: string) => {
    if (mobileScrollerRef?.scroll) {
      mobileScrollerRef?.scroll({ left: 0, behavior: 'smooth' });
    }

    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_CLICKED,
      component_name: COMPONENT_NAME.STAYCATION,
      component_action: COMPONENT_ACTION.STAYCATION.HRW_CITY_PILL_CLICKED,
      staycation: {
        city_pill_clicked: {
          city_name: label,
        },
      },
    });

    setActiveGroupIndex(index);
  };

  const handleOnClickCard = (card: InventoryCardProps, index: number) => {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_CLICKED,
      component_name: COMPONENT_NAME.STAYCATION,
      component_action: COMPONENT_ACTION.STAYCATION.CARD_CLICKED,
      staycation: {
        card_clicked: {
          index,
          hotel: {
            location_id: card.entityId,
            location_name: card.title,
          },
          price: {
            amount: card.priceRaw?.amount,
            currency: card.priceRaw?.currencyCode,
            unit: card.priceRaw?.unit,
          },
        },
      },
    });
  };

  const handleOnClickPageIndicator = () => {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_CLICKED,
      component_name: COMPONENT_NAME.STAYCATION,
      component_action: COMPONENT_ACTION.STAYCATION.HRW_ARROW_CLICKED,
    });
  };

  return (
    <div className={STYLES.Staycation}>
      <BpkSectionHeader title={title} description={description} />

      <div className={STYLES.Staycation__cityTabs}>
        <BpkMobileScrollContainer>
          <div className={STYLES.mobile_scroll_container_body}>
            {sections.map(({ label }, index) => (
              <BpkSelectableChip
                key={label}
                className={STYLES.Staycation__cityChip}
                accessibilityLabel={label}
                selected={index === activeGroupIndex}
                onClick={() => handleOnClickCityPill(index, label)}
              >
                {label}
              </BpkSelectableChip>
            ))}
          </div>
        </BpkMobileScrollContainer>
      </div>

      <CardRail
        bottomNav
        onClickPageIndicator={handleOnClickPageIndicator}
        mobileScrollerRef={(ref) => setMobileScrollerRef(ref)}
      >
        {sections[activeGroupIndex].cards.map((card, index) => (
          <InventoryCard
            key={card.title}
            {...card}
            onClick={() => handleOnClickCard(card, index)}
          />
        ))}
      </CardRail>
    </div>
  );
};

export default Staycation;
